// Generated by Framer (8107e78)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const serializationHash = "framer-BiwGI"

const variantClassNames = {VNU5k65Wh: "framer-v-19r0fv1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({categoria, color, height, id, width, ...props}) => { return {...props, R0VGxm5YT: color ?? props.R0VGxm5YT ?? "var(--token-10395782-897c-45d3-86df-951264ebf6d3, rgb(144, 110, 255))", ugPfJyIrr: categoria ?? props.ugPfJyIrr ?? "Notícias"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;categoria?: string;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ugPfJyIrr, R0VGxm5YT, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "VNU5k65Wh", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-19r0fv1", className, classNames)} data-framer-name={"Noticias"} layoutDependency={layoutDependency} layoutId={"VNU5k65Wh"} ref={refBinding} style={{borderBottomLeftRadius: 40, borderBottomRightRadius: 40, borderTopLeftRadius: 40, borderTopRightRadius: 40, ...style}}><motion.div className={"framer-1t55gfv"} data-framer-name={"Bg Color"} layoutDependency={layoutDependency} layoutId={"JhcwSaVVG"} style={{backgroundColor: R0VGxm5YT, opacity: 0.2}}/><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7U2F0b3NoaS1tZWRpdW0=", "--framer-font-family": "\"Satoshi\", \"Satoshi Placeholder\", sans-serif", "--framer-font-size": "11px", "--framer-font-weight": "500", "--framer-line-height": "120%", "--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-R0VGxm5YT-W1E9W8JHq))", "--framer-text-transform": "uppercase"}}>Notícias</motion.p></React.Fragment>} className={"framer-141h8kj"} fonts={["FS;Satoshi-medium"]} layoutDependency={layoutDependency} layoutId={"AQGWR8UJl"} style={{"--extracted-r6o4lv": "var(--variable-reference-R0VGxm5YT-W1E9W8JHq)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--variable-reference-R0VGxm5YT-W1E9W8JHq": R0VGxm5YT}} text={ugPfJyIrr} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-BiwGI.framer-rtwipa, .framer-BiwGI .framer-rtwipa { display: block; }", ".framer-BiwGI.framer-19r0fv1 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 2px 6px 2px 6px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-BiwGI .framer-1t55gfv { flex: none; height: 100%; left: calc(50.00000000000002% - 100% / 2); overflow: visible; pointer-events: none; position: absolute; top: calc(47.05882352941179% - 100% / 2); width: 100%; z-index: 0; }", ".framer-BiwGI .framer-141h8kj { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-BiwGI.framer-19r0fv1 { gap: 0px; } .framer-BiwGI.framer-19r0fv1 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-BiwGI.framer-19r0fv1 > :first-child { margin-left: 0px; } .framer-BiwGI.framer-19r0fv1 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 17
 * @framerIntrinsicWidth 62
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"ugPfJyIrr":"categoria","R0VGxm5YT":"color"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerW1E9W8JHq: React.ComponentType<Props> = withCSS(Component, css, "framer-BiwGI") as typeof Component;
export default FramerW1E9W8JHq;

FramerW1E9W8JHq.displayName = "Button/Categoria";

FramerW1E9W8JHq.defaultProps = {height: 17, width: 62};

addPropertyControls(FramerW1E9W8JHq, {ugPfJyIrr: {defaultValue: "Notícias", displayTextArea: false, title: "Categoria", type: ControlType.String}, R0VGxm5YT: {defaultValue: "var(--token-10395782-897c-45d3-86df-951264ebf6d3, rgb(144, 110, 255))", title: "Color", type: ControlType.Color}} as any)

addFonts(FramerW1E9W8JHq, [{explicitInter: true, fonts: [{family: "Satoshi", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/P2LQKHE6KA6ZP4AAGN72KDWMHH6ZH3TA/ZC32TK2P7FPS5GFTL46EU6KQJA24ZYDB/7AHDUZ4A7LFLVFUIFSARGIWCRQJHISQP.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})